<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <div class="form-row justify-content-end">
          <div class="col-md-4">
            <input type="search" class="form-control d-inline-block" placeholder="Pesquisar..." id="filterInput"
              v-model="filters.name" />
          </div>

          <div class="col-md-1">
            <button @click="getData()" type="button" class="btn btn-block btn-primary">
              <i class="bi bi-search"></i>
            </button>
          </div>
        </div>
      </div>

      <b-table sticky-header class="position-relative" :items="dataItems" responsive :fields="tableColumns"
        primary-key="id" show-empty empty-filtered-text="Nenhum registro encontrado"
        empty-text="Nenhum registro encontrado">
        <template #cell(name)="data">
          {{ data.item.name }}
        </template>

        <template #cell(group)="{ item }">
          <b-badge :variant="`light-${item.group.code === '1' ? 'success' : 'danger'}`">{{ item.group.title }}</b-badge>
        </template>

        <template #cell(actions)="data">
          <div class="form-row align-items-center justify-content-center">
            <div class="col-md-6 text-center">
              <feather-icon :id="`customer-${data.item.uuid}-delete-icon`" icon="TrashIcon"
                class="cursor-pointer cursor text-danger" size="16" @click="confirmDelete(data.item.uuid)" />
            </div>
            <div class="col-md-6 text-center">
              <feather-icon :id="`customer-${data.item.uuid}-edit-icon`" icon="EditIcon" class="cursor-pointer cursor"
                size="16" @click="modalEdit(data.item)" />
            </div>
          </div>
        </template>
      </b-table>

      <CustomPaginateTable :rows-table="rowsTable" :current-page="filters.currentPage" :per-page="filters.perPage"
        :total-rows="totalRows" />

      <b-modal id="modal-create-order-purchase-cost-center" modal-class="modal-create" :title="titleModal" centered
        hide-footer>
        <b-form @submit.prevent="submitCreate">
          <div class="form-row">
            <div class="col-md-4">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Nome
              </label>
              <div class="form-group">
                <b-form-input v-model="item.name" :class="{ 'is-invalid': $v.item.name.$error }" />
              </div>
            </div>

            <div class="col-md-4">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Grupo
              </label>
              <div class="form-group">
                <v-select label="title" item-text="title" item-value="code" v-model="item.group" :options="optionsGroup"
                  :class="{ 'is-invalid': $v.item.group.$error }" :searchable="false">
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>

            <div class="col-md-4">
              <label for="">
                <i class="text-danger bi bi-record-circle"></i>
                Status
              </label>
              <div class="form-group">
                <v-select label="title" item-text="title" item-value="code" v-model="item.active" :options="optionsStatus"
                  :class="{ 'is-invalid': $v.item.active.$error }" :searchable="false">
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>
          </div>

          <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
            <h5>
              <div class="list-business-units">
                <div><i class="text-danger bi bi-record-circle"></i> Unidades de negócio</div>
                <small style="margin-top: 5px">Selecione 1 ou mais unidade de negócio que fará parte do centro de
                  custo.</small>
              </div>
            </h5>

            <vue-perfect-scrollbar v-if="optionsBusinessUnits.length" :settings="{ maxScrollbarLength: 60 }" class="sidebar-menu-list scroll-area">
              <b-list-group stacked class="list-group list-group-messages">
                <b-list-group-item v-for="(element, index) in optionsBusinessUnits" :key="index">
                  <div class="d-flex" style="align-items: center;">
                    <div>
                      <b-form-checkbox :id="`bu-${index}`" :name="`bu-${index}`" :value="element.code"
                        :class="`custom-control-primary`" v-model="item.business_units" class="mb-0"></b-form-checkbox>
                    </div>
                    <div>
                      {{ element.title }}
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </vue-perfect-scrollbar>

            <div v-else class="alert alert-dark p-1 mb-0">
              Nenhuma <strong>unidade de negócio</strong> foi cadastrada até o momento.
            </div>
          </div>

          <div class="form-row justify-content-end modal-footer-custom">
            <div class="col-md-7 col-12">
              <button type="button" @click="
                $bvModal.hide('modal-create-order-purchase-cost-center')
                " class="btn btn-light float-left">
                Fechar
              </button>
              <ButtonsActionsFooter variant="success" :submited="submited" :text="textSubmit" subtext="Aguarde..." />
            </div>
          </div>
        </b-form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  BPagination,
  BSpinner,
  BModal,
  BFormGroup,
  BTooltip,
  BForm,
  BCardText,
  BBadge,
  BFormRow,
  BListGroup,
  BListGroupItem,
  BContainer,
  BFormCheckbox,
  BIcon
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";
import { required } from "vuelidate/lib/validators";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    BIcon,
    BCard,
    CustomPaginateTable,
    BModal,
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BTable,
    BButton,
    BPagination,
    vSelect,
    BTooltip,
    BForm,
    BCardText,
    BFormRow,
    BContainer,
    ButtonsActionsFooter,
    BSpinner,
    BBadge,
    VuePerfectScrollbar,
    BListGroup,
    BListGroupItem,
  },
  beforeDestroy() {
    this.$root.$off("paginate:update");
  },
  data() {
    return {
      filters: {
        currentPage: 1,
        perPage: 100,
        name: "",
        group: "",
        active: "",
      },
      uuid: null,
      textSubmit: "",
      submited: false,
      filter: null,
      filterOn: [],
      totalRows: 0,
      rowsTable: 0,
      tableColumns: [
        {
          key: "name",
          label: "Nome",
        },
        {
          key: "group",
          label: "Grupo",
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "created_at",
          label: "Cadastrado",
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "actions",
          label: "",
          class: "text-center",
          thStyle: "width: 80px",
        },
      ],
      dataItems: [],
      titleModal: "",
      item: {
        name: "",
        active: "",
        group: "",
        business_units: [],
      },
      optionsStatus: [
        { code: 0, title: "Inativo" },
        { code: 1, title: "Ativo" },
      ],
      optionsGroup: [
        { code: 1, title: "Receita" },
        { code: 2, title: "Despesa" },
      ],
      optionsBusinessUnits: [],
    };
  },
  validations: {
    item: {
      name: {
        required,
      },
      group: {
        required,
      },
      active: {
        required,
      },
      business_units: {
        required
      },
    },
  },
  mounted() {
    this.getData();

    this.$store.dispatch("BusinessUnit/forSelect", '').then((res) => {
      this.optionsBusinessUnits = res;
    });

    this.$root.$on("open:modal-create-order-purchase-cost-center", () => {
      this.clearForm();
      this.textSubmit = "Cadastrar";
      this.titleModal = "Cadastrar Centro de custo";
      this.$bvModal.show("modal-create-order-purchase-cost-center");
    });
  },
  beforeDestroy() {
    this.$root.$off("open:modal-create-order-purchase-cost-center");
  },
  methods: {
    modalEdit(element) {
      this.textSubmit = "Salvar";
      this.titleModal = "Editar";
      this.clearForm();

      this.item = element;
      this.$bvModal.show("modal-create-order-purchase-cost-center");
    },
    submitCreate() {
      this.$v.$touch();
      this.submited = true;

      if (!this.$v.$error) {
        if (this.textSubmit === "Cadastrar") {
          this.$store
            .dispatch("CostCenter/store", this.item)
            .then(() => {
              this.notifyDefault("success");
              this.getData();
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-order-purchase-cost-center");
                this.clearForm();
              });
            })
            .finally(() => {
              this.submited = false;
            });
        } else {
          this.$store
            .dispatch("CostCenter/update", {
              uuid: this.item.uuid,
              data: this.item,
            })
            .then(() => {
              this.getData();
              this.notifyDefault("success");
              this.$nextTick(() => {
                this.$bvModal.hide("modal-create-order-purchase-cost-center");
                this.clearForm();
              });
            })
            .catch(() => {
              this.submited = false;
            })
            .finally(() => {
              this.submited = false;
            });
        }
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    clearForm() {
      this.item = {
        name: "",
        active: "",
        group: "",
        business_units: [],
      };
    },
    async getData() {
      this.$store.dispatch("CostCenter/all", this.filters).then((res) => {
        const data = res;
        this.dataItems = data.data;
        this.rowsTable = data.data.length;
        this.totalRows = data.meta.total;
        this.filters.currentPage = data.meta.current_page;
      });
    },
    updatePage() {
      this.getData();
    },
    confirmDelete(uuid) {
      this.$swal({
        title: "Quer excluir?",
        text: "Esta ação será irreversível!",
        icon: "error",
        showCancelButton: true,
        confirmButtonText: "Sim, quero excluir!",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-light ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("CostCenter/delete", uuid).then(() => {
            this.getData();
            this.$swal({
              icon: "success",
              title: "Deletado!",
              text: "Operação realizada com sucesso.",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          });
        }
      });
    },
  },
  directives: {
    Ripple,
  },
};
</script>

<style lang="scss" scoped>
.list-business-units {
  display: grid !important;
  align-content: center;
  align-items: center;
  justify-content: center;
}
</style>